import styled from '@emotion/styled';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Input,
  SxProps,
  Typography,
} from '@mui/material';
import { uniqBy } from 'lodash';
import { useRef, useState } from 'react';

import { CustomPaper } from '@mgk-eld/core';
import { blue } from '@mgk-eld/utils';
import { CancelIcon, SearchWhiteIcon } from '@mgk-eld/assets';

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export type FileInstance = File | { id: number; link: string };
export type FileType = FileInstance[];

type Props = {
  selectedFiles?: FileType;
  setSelectedFiles: (files: FileType) => void;
  setDeletedFiles: (id: number) => void;
  acceptableTypes: string[];
  fileSize: number;
  generateLink?: (id: number) => Promise<void>;
  inputSx?: SxProps;
  placeholder: string;
};

export const MultiFileUploader: React.FC<Props> = ({
  selectedFiles = [],
  setSelectedFiles,
  setDeletedFiles,
  acceptableTypes,
  fileSize,
  generateLink,
  inputSx,
  placeholder,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [downloading, setDownloading] = useState<number[]>([]);

  const maxFileSize = fileSize * 1024 ** 2;
  const inMB = maxFileSize / 1024 ** 2;
  const acceptedList = acceptableTypes.join(',');

  const handleBrowseClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileArray = Array.from(files);
      const invalidFiles = fileArray.filter((file) => file.size > maxFileSize);

      if (invalidFiles.length > 0) {
        setError(`Some files exceed the ${inMB}MB size limit`);
        setTimeout(() => {
          setError(null);
        }, 5000);
        return;
      }

      const fls: File[] = selectedFiles.filter(
        (f): f is File => f instanceof File
      );

      const uniques = uniqBy([...fls, ...fileArray], 'name');

      const nonFiles: { id: number; link: string }[] = selectedFiles.filter(
        (f): f is { id: number; link: string } => !(f instanceof File)
      );

      setSelectedFiles([...nonFiles, ...uniques]);
    }
  };

  const handleCancel = (file: any) => {
    const files: File[] = selectedFiles.filter(
      (f): f is File => f instanceof File
    );

    const nonFiles: { id: number; link: string }[] = selectedFiles.filter(
      (f): f is { id: number; link: string } => !(f instanceof File)
    );

    if (file instanceof File) {
      const filtered = files.filter((f) => f.name !== file.name);

      setSelectedFiles([...nonFiles, ...filtered]);
    } else {
      const filtered = nonFiles.filter((nf) => nf.id !== file.id);

      setSelectedFiles([...filtered, ...files]);

      setDeletedFiles(file.id);
    }
  };

  const handleDownloadClick = async (file: FileInstance) => {
    const isFile = file instanceof File;
    if (!isFile && generateLink) {
      setDownloading((prev) => [...prev, file.id]);
      await generateLink(file.id);
      setDownloading((prev) => prev.filter((d) => d !== file.id));
    }
  };

  return (
    <CustomPaper
      sx={{
        display: 'flex',
        alignItems: 'left',
        flexDirection: 'column',
      }}
    >
      <Input
        id="file"
        placeholder={placeholder}
        disabled
        fullWidth
        disableUnderline
        sx={{ pl: 4, ...inputSx }}
        endAdornment={
          <Button
            variant="contained"
            color="info"
            endIcon={<SearchWhiteIcon color="white" />}
            sx={{ px: '25px', height: '38px' }}
            onClick={handleBrowseClick}
          >
            Browse
            <VisuallyHiddenInput
              ref={fileInputRef}
              type="file"
              accept={`${acceptedList}`}
              onChange={handleFileChange}
              multiple
            />
          </Button>
        }
      />
      <Typography component={'span'} color={blue[250]}>
        Maximum file size is {inMB}MB. Accepted file{' '}
        {acceptableTypes.length > 1 ? 'types are ' : 'type is '}
        {acceptableTypes.join(', ').toLocaleUpperCase()}
      </Typography>
      {error && (
        <Typography component={'span'} color="error" sx={{ mt: 1 }}>
          {error}
        </Typography>
      )}

      <Grid container flexDirection={'column'} mt={6}>
        {selectedFiles.map((file, i) => {
          const isFile = file instanceof File;
          return (
            <Grid
              item
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              key={i}
            >
              <Typography
                sx={{ cursor: isFile ? 'inherit' : 'pointer' }}
                onClick={() => handleDownloadClick(file)}
              >
                {isFile ? file.name : file.link}
              </Typography>
              <IconButton
                onClick={() => handleCancel(file)}
                disabled={!isFile && downloading.includes(file.id)}
              >
                {!isFile && downloading.includes(file.id) ? (
                  <CircularProgress color="info" size={16} />
                ) : (
                  <CancelIcon />
                )}
              </IconButton>
            </Grid>
          );
        })}
      </Grid>
    </CustomPaper>
  );
};

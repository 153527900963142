export const USER_ROLES = {
  driver: {
    id: 5,
    name: "Driver",
    code: "driver",
  },
  fleet_manager: {
    id: 4,
    name: "Freet Manager",
    code: "fleet_manager",
  },
};

import { CertificationIcon, LoginIcon, LogOutIcon, PowerDownIcon, PowerUpIcon } from '@mgk-eld/assets';
import { Row } from '.';
import { useAppSelector } from '../../../../hooks/reduxHooks';
import { setSelectedRect } from '../../../../redux/simple/slice';
import { RootState } from '../../../../redux/store';
import { ExtendedStatusMode } from '../types';
import { formatAMPM, getSeconds } from '../utils';
import { getDateFromSeconds } from './utils';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, {
  Dispatch,
  FunctionComponent,
  SVGProps,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Range } from 'react-range';
import { useDispatch } from 'react-redux';

type EventRows = {
  rows: Array<Row>;
  sethighlightedRowId: Dispatch<SetStateAction<number>>;
};

const getIcon = (
  status: ExtendedStatusMode
): FunctionComponent<SVGProps<SVGSVGElement>> => {
  switch (status) {
    case 'CERTIFICATION':
      return CertificationIcon;
    case 'ENGINE_POWER_UP':
      return PowerUpIcon;
    case 'ENGINE_SHUT_DOWN':
      return PowerDownIcon;
    case 'LOGIN':
      return LoginIcon;
    case 'LOGOUT':
      return LogOutIcon;
    default:
      return CertificationIcon;
  }
};

export const Events: React.FC<EventRows> = ({ rows, sethighlightedRowId }) => {
  const dispatch = useDispatch();
  const drafts = useAppSelector((state: RootState) => state.drafts);
  const [values, setValues] = useState<number[]>();

  useEffect(() => {
    setValues(rows.map((row) => getSeconds(new Date(row.start))));
  }, [rows, drafts]);

  return (
    <div
      style={{
        position: 'relative',
        top: '-35px',
      }}
    >
      {values && (
        <Range
          allowOverlap
          values={values}
          step={1}
          min={0}
          max={86400}
          onChange={() => {
            //
          }}
          renderTrack={({ props: railProps, children }) => (
            <Box
              {...railProps}
              style={{
                ...railProps.style,
                width: '100%',
                borderRadius: '4px',
              }}
            >
              {children}
            </Box>
          )}
          renderThumb={({ props: handleProps, isDragged, index }) => {
            const row = rows[index];
            if (!row) return;

            const Icon = getIcon(row.status);

            return (
              <Box
                className="slider Event"
                {...handleProps}
                sx={{
                  ...handleProps.style,
                }}
                onMouseEnter={() => sethighlightedRowId(row.id)}
                onMouseLeave={() => sethighlightedRowId(-1)}
                onMouseDown={() => dispatch(setSelectedRect(row.id))}
              >
                <Icon
                  style={{
                    width: '14px',
                  }}
                />
                <Typography component="span" className="EventPoint">
                  {formatAMPM(
                    new Date(getDateFromSeconds(values[index]).format())
                  )}
                </Typography>
              </Box>
            );
          }}
        />
      )}
    </div>
  );
};

import { timezones } from '@mgk-eld/utils';
import { RootState } from '../store';
import { createSelector } from '@reduxjs/toolkit';

export const getSelectedCompany = (state: RootState) =>
  state.companies.selectedCompany;

export const getIsCompanyLoading = (state: RootState) =>
  state.companies.loading;

export const getSelectedCompanyId = createSelector(
  [getSelectedCompany],
  (company) => company?.id ?? null
);

export const getCompaniesList = (state: RootState) =>
  state.companies.companiesList;

export const getTimeZone = createSelector(
  [getSelectedCompany],
  (company) => company?.timezone
);

export const getCompanyPaymentIsOnline = createSelector(
  [getSelectedCompany],
  (company) => company?.online_payment
);
export const getCompanyTrusted = createSelector(
  [getSelectedCompany],
  (company) => company?.trusted
);

export const getCompanySubscriptionStartDate = createSelector(
  [getSelectedCompany],
  (company) => company?.subscription_start_date
);
export const getTimeZoneShortHand = createSelector(
  [getSelectedCompanyId, getTimeZone],
  (id, tz) => {
    if (tz) return timezones.find((t) => t.timezone === tz)?.value;
  }
);

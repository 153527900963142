import { useMediaQuery } from '@mui/material';
import { AbilityProvider } from './contexts/AbilityContext';
import { AuthProvider } from './contexts/JWTContext';
import ErrorBoundary from './layout/ErrorBoundary';
import { store } from './redux/store';
import routes from './routes';
import { CacheProvider } from '@emotion/react';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  changeAppFavion,
  createEmotionCache,
  createTheme,
  getAppConfig,
  updateBaseUrl,
  updateWsBaseUrl,
} from '@mgk-eld/utils';
import { useCallback, useEffect, useState } from 'react';

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);
  const [theme, setTheme] = useState(createTheme());
  const [appConfigIsLoading, setAppConfigIsLoading] = useState(true);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [title, setTitle] = useState('ELD');

  const getConfig = useCallback(async () => {
    const config = await getAppConfig();
    // change title
    setTitle(config.name ?? 'MGK ELD');

    // change https
    updateBaseUrl(`${config.domain}/api`);

    // change WS
    updateWsBaseUrl(`${config.domain}/ws/`);

    // change favicon
    changeAppFavion(config.favicon as string);

    // change theme colors
    setTheme(createTheme(config.theme_config));

    // app is ready to render
    setAppConfigIsLoading(false);
  }, []);

  useEffect(() => {
    getConfig();
  }, [getConfig]);

  return appConfigIsLoading ? (
    ''
  ) : (
    <CacheProvider value={emotionCache}>
      <ToastContainer position={isDesktop ? 'top-right' : 'bottom-right'} />
      <HelmetProvider>
        <Helmet
          titleTemplate={`%s | ${title} User portal`}
          defaultTitle={`${title} User portal`}
        />
        <Provider store={store}>
          <MuiThemeProvider theme={theme}>
            <AuthProvider>
              <AbilityProvider>
                <ErrorBoundary>{content}</ErrorBoundary>
              </AbilityProvider>
            </AuthProvider>
          </MuiThemeProvider>
        </Provider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;

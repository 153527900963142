import { AbilityContext } from "../contexts/AbilityContext";
import { useContext } from "react";

const useAbility = () => {
  const context = useContext(AbilityContext);

  if (!context)
    throw new Error("AbilityContext must be placed within AbilityProvider");

  return context;
};

export default useAbility;

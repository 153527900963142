import { PageOptions, axiosInstance, exportExcel } from '@mgk-eld/utils';
import { Logs } from './logs';

export enum ErrorUrls {
  DelayedEvents = 'logs/undefined',
  Warnings = 'warnings',
  DriverForms = 'driver-forms',
  Violations = 'violations',
  UndefinedDriverProfile = 'logs/unidentified',
}

export type FormData = {
  driver_id: number;
  date: string;
  company_id: number;
  data: {
    vehicle_id: number;
    codriver_id: number;
    trailer: string;
    shipping_docs: string;
    signed: boolean;
  };
};

const load = (url: ErrorUrls, paginationOptions: PageOptions, props: Logs) => {
  return axiosInstance.get(`${url}`, {
    params: { ...paginationOptions, ...props },
  });
};

const updateForm = (props: FormData) => {
  return axiosInstance.put(`${ErrorUrls.DriverForms}`, {
    ...props,
  });
};

const deleteErrors = (url: ErrorUrls, props: Array<string | number>) => {
  return axiosInstance.delete(`${url}`, {
    data: { ids: props },
  });
};

const hideViolations = (
  props: Array<{
    company_id: number;
    driver_id: number;
    datetime: string;
    code: number;
  }>
) => {
  return axiosInstance.post(`violations`, {
    violations: props,
  });
};

const assignEvent = (id: number | string) =>
  axiosInstance.patch(`logs/undefined/${id}/assign`);

const downloadXLSX = async (company_id: number, date: string) => {
  return exportExcel(
    `${ErrorUrls.UndefinedDriverProfile}/download/xlsx`,
    { company_id, date },
    'undp_logs.xlsx',
    axiosInstance.defaults.headers.common
  );
};

const errorsApi = {
  load,
  deleteErrors,
  assignEvent,
  updateForm,
  hideViolations,
  downloadXLSX,
};

export default errorsApi;

import { TrashIcon } from '@mgk-eld/assets';
import { CustomDialog } from '@mgk-eld/core';
import React, { useCallback, useState } from 'react';

const DeleteButton: React.FC<{
  isSelected: boolean;
  id?: number;
  onDelete?: Function;
  text?: string;
}> = ({ isSelected, id, text, onDelete }) => {
  const [open, setOpen] = useState<number | boolean>(false);

  const handleDelete = useCallback(() => {
    const idDelete = id ?? undefined;
    if (onDelete) onDelete(idDelete);
    setOpen(false);
  }, [id, onDelete]);

  return (
    <>
      <CustomDialog
        questionText={`Do you want to delete ${text}?`}
        actionText="Delete"
        actionColor="error"
        open={Boolean(open)}
        setOpen={setOpen}
        handleSubmit={handleDelete}
      />
      <TrashIcon
        style={{
          cursor: isSelected ? 'pointer' : 'not-allowed',
          filter: isSelected ? '' : 'brightness(0) invert(50%)',
        }}
        onClick={() => {
          if (isSelected) {
            setOpen(id ?? true);
          }
        }}
      />
    </>
  );
};

export default DeleteButton;

import { dayjs, Dayjs, grey, white } from '@mgk-eld/utils';
import './styles.css';
import styled from '@emotion/styled';
import {
  Button,
  IconButton,
  InputAdornment,
  Popper,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarIcon } from '@mgk-eld/assets';
import { YYYY_MM_DD_format, CalendarCustomPaper } from '@mgk-eld/core';

const CustomButton = styled(Button)`
  color: white;
  &:hover {
    color: black;
    background-color: ${grey[100]};
  }
`;

interface CalendarPickerProps {
  selectedDate: Dayjs;
  onDateChange: (date: Dayjs, end?: Dayjs) => void;
  maxDate?: Dayjs;
  minDate?: Dayjs;
  tz?: string;
}

const getFormatedYear = (date: Date) => {
  return dayjs(date).format('MMM, YYYY');
};

const getFormatedQuarter = (date: Date) => {
  return `${dayjs(date).format('YYYY')}, Q${dayjs(date).quarter()}`;
};
export const CalendarPicker: React.FC<SxProps & CalendarPickerProps> = ({
  selectedDate,
  onDateChange,
  tz,
  minDate,
  maxDate,
  ...sxProps
}) => {
  const [open, setOpen] = useState(false);
  const [isM, setM] = useState(false);
  const [isY, setY] = useState(false);
  const [isQ, setQ] = useState(false);
  const anchorEl = useRef<HTMLDivElement | null>(null);

  const setAllPickersFalse = () => {
    setQ(false);
    setY(false);
    setM(false);
  };

  const setQuarter = (prev: boolean) => {
    setAllPickersFalse();
    setQ(!prev);
  };

  const setYear = (prev: boolean) => {
    setAllPickersFalse();
    setY(!prev);
  };

  const handleClose = () => {
    setOpen(false);
    setAllPickersFalse();
  };

  return (
    <>
      <CalendarCustomPaper
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '75%',
          ...sxProps,
        }}
      >
        <TextField
          ref={anchorEl}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setOpen((prev) => !prev)}>
                  <CalendarIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            '& fieldset': { border: 'none' },
          }}
          fullWidth
          inputProps={{ 'aria-label': 'date' }}
          value={selectedDate.format(YYYY_MM_DD_format)}
          id="date"
        />
      </CalendarCustomPaper>
      <Popper sx={{ zIndex: 99999 }} open={open} anchorEl={anchorEl.current}>
        <DatePicker
          minDate={minDate ? minDate.toDate() : undefined}
          maxDate={
            maxDate
              ? maxDate.toDate()
              : new Date(dayjs.tz(new Date(), tz).format('MM-DD-YYYY'))
          }
          selected={
            selectedDate
              ? selectedDate.toDate()
              : dayjs.tz(new Date(), tz).toDate()
          }
          onChange={(date) => {
            const period = isY ? 'year' : isQ ? 'quarter' : undefined;
            if (date)
              onDateChange(
                dayjs(date),
                !period ? period : dayjs(date).endOf(period)
              );
            handleClose();
          }}
          className={
            'react-datepicker__header react-datepicker__day-names react-datepicker__year react-datepicker__year-wrapper '
          }
          dayClassName={(day) => 'day'}
          onClickOutside={handleClose}
          inline
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <Stack
              direction={'row'}
              justifyContent="space-around"
              alignContent="center"
              pb={1}
            >
              <Stack
                direction={'row'}
                justifyContent="center"
                alignContent="center"
              >
                <Typography sx={{ color: white, pt: 1 }}>
                  {getFormatedYear(date)}
                </Typography>
                <CustomButton
                  size="small"
                  sx={{
                    minWidth: 32,
                    height: 32,
                  }}
                  variant="outlined"
                  onClick={() => setYear(isY)}
                >
                  &#9660;
                </CustomButton>
              </Stack>
              <div>
                <CustomButton
                  size="small"
                  variant="outlined"
                  onClick={() => setQuarter(isQ)}
                >
                  {getFormatedQuarter(date)}
                </CustomButton>
                <CustomButton
                  sx={{
                    minWidth: 32,
                    height: 32,
                  }}
                  size="small"
                  variant="outlined"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  {'<'}
                </CustomButton>
                <CustomButton
                  sx={{
                    // borderRadius: 8,
                    minWidth: 32,
                    height: 32,
                  }}
                  size="small"
                  variant="outlined"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  {'>'}
                </CustomButton>
              </div>
            </Stack>
          )}
          showQuarterYearPicker={isQ}
          showMonthYearPicker={isM}
          showYearPicker={isY}
        />
      </Popper>
    </>
  );
};

import { AppUser, AuthState } from "../../types/auth";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    initializeAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
      state.isInitialized = true;
    },
    signIn: (state) => {
      state.isAuthenticated = true;
    },
    updateUserInfo: (state, action: PayloadAction<AppUser>) => {
      state.user = action.payload;
    },
    signOut: (state) => {
      state.isAuthenticated = false;
      state.user = null;
    },
  },
});

export const { signIn, signOut, initializeAuth, updateUserInfo } =
  authSlice.actions;

export default authSlice.reducer;

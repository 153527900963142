import styled from '@emotion/styled';

const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    background: #f8f8f8;
  }
  ::-webkit-scrollbar-thumb {
    background: #a6a9b2;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #a6a9b2;
  }
`;

export const CustomScrollBar = ({ children, ...props }: any) => {
  return <ScrollContainer {...props}>{children}</ScrollContainer>;
};

import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import { lighten } from '@mui/material';
import Button from '@mui/material/Button';

export const CustomActionButton = styled(Button)`
  width: 127px;
  height: 40px;
  border-radius: 8px;
  &:hover {
    background: ${(props) => lighten(props.theme.palette.primary.light, 0.1)};
    color: white !important;
    border-color: ${(props) => lighten(props.theme.palette.primary.light, 0.1)};

    span svg path {
      transition: all 0.3s ease;
      fill: white !important;
    }
  }

  span svg path {
    transition: all 0.3s ease;
  }
`;

export const CustomButton = styled(Button)<{
  selected?: boolean;
  width?: number;
  disabled?: boolean;
}>`
  width: ${(props) => props.width ?? 94}px;
  height: 32px;
  border-radius: 8px;
  border: ${(props) => (props.selected ? '1.5px solid #E6EFF7' : 'none')};

  &:hover {
    background: ${(props) => lighten(props.theme.palette.secondary.light, 0.1)};
    border: ${(props) =>
      props.selected
        ? `1.5px solid ${props.theme.palette.secondary.main}`
        : 'none'};
    color: white;
  }

  background: ${(props) =>
    props.selected
      ? props.theme.palette.background.paper
      : props.theme.palette.secondary.main};
  color: ${(props) =>
    props.selected
      ? props.theme.palette.text.primary
      : props.theme.palette.secondary.contrastText};

  ${(props) =>
    props.disabled &&
    `
    color: white !important;
    opacity: 0.3;
    pointer-events: none;
  `}
`;

export const ReportButton = styled(LoadingButton)`
  border-radius: 8px;
  padding: 11px 40px;
`;

export const LogsReportButton = styled(LoadingButton)`
  border-radius: 8px;
  padding: 8px 40px;
`;

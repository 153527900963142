import styled from '@emotion/styled';
import { grey } from '@mgk-eld/utils';
import { InputLabel, SelectChangeEvent, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

type Props = {
  options: string[];
  state: string[];
  setState: (value: string[]) => void;
  label?: string;
  width?: string;
  props?: FormControlProps;
  placeholder?: string;
};

const ReliableMenuItem = styled(MenuItem)`
  height: 36px;
  background-color: white !important;
  :hover {
    background-color: #e6eff7 !important;
  }
`;

export function MultiCheckBoxSelect({
  options,
  state,
  setState,
  label = 'Status',
  width = '264px',
  props,
  placeholder,
}: Props) {
  const handleChangeStatus = (event: SelectChangeEvent<typeof state>) => {
    const {
      target: { value },
    } = event;
    setState(typeof value === 'string' ? value.split(',') : value);
  };
  return (
    <FormControl id="state" size="small" sx={{ width: width }} {...props}>
      <InputLabel id="state">
        <Typography component="span">{label}</Typography>
      </InputLabel>
      <Select
        multiple
        id="state"
        label={label}
        value={state}
        displayEmpty={Boolean(placeholder)}
        renderValue={(selected) => {
          if (selected.length === 0 && placeholder) {
            return <Typography color={grey[400]}>{placeholder}</Typography>;
          }

          return selected.join(', ');
        }}
        onChange={handleChangeStatus}
      >
        {options.map((i) => (
          <ReliableMenuItem key={i} value={i}>
            <Checkbox
              size={props?.size || 'small'}
              checked={state.indexOf(i) > -1}
            />
            <ListItemText primary={i} />
          </ReliableMenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

import { MenuOption } from '@mgk-eld/utils';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material';
import React, { useCallback } from 'react';

export const SingleSelect: React.FC<
  {
    items: MenuOption[];
    showErrorMessage?: boolean;
    errorMessage?: string;
    setValue: (val: string) => void;
  } & SelectProps
> = ({
  items,
  showErrorMessage,
  errorMessage,
  setValue,
  id,
  error,
  title,
  value,
  disabled,
  ...rest
}) => {
  const handleChange = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      setValue(e.target.value as string);
    },
    [setValue]
  );
  return (
    <FormControl id={id} variant="filled" fullWidth error={error}>
      <InputLabel id={id}>{title}</InputLabel>
      <Select
        {...rest}
        id={id}
        value={value}
        label={title}
        disabled={disabled}
        onChange={handleChange}
      >
        {items.map((item) => (
          <MenuItem
            disabled={item.disabled}
            key={item.value}
            value={item.value}
          >
            {item.title}
          </MenuItem>
        ))}
      </Select>
      {showErrorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

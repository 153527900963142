import { axiosInstance } from '@mgk-eld/utils';

const login = (username: string, password: string) => {
  return axiosInstance.post(
    `auth/login`,
    { username, password },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
};
const getDriverToken = (body: { driver_id: number; expire_minutes: number }) =>
  axiosInstance.post(`auth/driver-token`, body);

const authApi = {
  login,
  getDriverToken,
};

export default authApi;

import d, { Dayjs as D } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

d.extend(utc);
d.extend(timezone);
d.extend(isToday);
d.extend(quarterOfYear);

export const dayjs = d;

export type Dayjs = D;

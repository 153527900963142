import React, { useCallback, useEffect, useState } from 'react';
import { EditContainer } from './EditInput';
import { Autocomplete, TextField, Tooltip } from '@mui/material';
import { StyledPopper } from '../../../components/navbar/NavbarSelect';
import styled from '@emotion/styled';
import { CustomPaper } from '@mgk-eld/core';
import { useDebounce } from '@mgk-eld/utils';

const StyledAutoCompletePopper = styled(StyledPopper)(() => ({
  height: 'auto',
}));

export type Option = { label: string; value: number };

const EditDropdown: React.FC<{
  value?: string;
  getOptions: (v: string) => Promise<Option[]>;
  setValue: (v: Option | null) => void;
}> = ({ value, getOptions, setValue }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setOpen] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<Option[]>([]);

  const debouncedInputValue = useDebounce(inputValue, 500);

  const handleBlur = useCallback(() => {
    setIsEditing(false);
    setOpen(false);
  }, []);

  const stopPropagation = useCallback((e: any) => {
    e.stopPropagation();
    setOpen((prev) => !prev);
  }, []);

  const handleInputChange = useCallback((e: unknown, v: string) => {
    setInputValue(v);
  }, []);

  const handleChange = useCallback(
    (val: Option | null) => {
      setValue(val);
      setTimeout(() => {
        setIsEditing(false);
      }, 100);
    },
    [setValue]
  );

  const fetchOptions = useCallback(
    async (val: string) => {
      if (!isEditing) return;
      setLoading(true);
      const items = await getOptions(val);
      setOptions(items);
      setLoading(false);
    },
    [getOptions, isEditing]
  );

  useEffect(() => {
    fetchOptions(debouncedInputValue);
  }, [fetchOptions, debouncedInputValue]);

  return (
    <EditContainer
      onKeyDown={(e) => e.stopPropagation()}
      onClick={() => setIsEditing(true)}
    >
      {!isEditing ? (
        <Tooltip placement="top" title={value}>
          <div>{value}</div>
        </Tooltip>
      ) : (
        <Autocomplete
          id="autocomplete"
          PopperComponent={StyledAutoCompletePopper}
          noOptionsText="No Option"
          fullWidth
          inputValue={inputValue}
          onInputChange={handleInputChange}
          onChange={(e, opt) => {
            handleChange(opt);
            handleBlur();
          }}
          open={isOpen}
          options={options}
          loading={loading}
          clearIcon={null}
          renderInput={(params) => (
            <CustomPaper
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                height: 40,
              }}
            >
              <TextField
                autoFocus
                onBlur={handleBlur}
                onClick={stopPropagation}
                onFocus={() => setOpen(true)}
                {...params}
                sx={{
                  '& fieldset': { border: 'none' },
                }}
              />
            </CustomPaper>
          )}
        />
      )}
    </EditContainer>
  );
};

export default EditDropdown;

import { CopyIcon } from '@mgk-eld/assets';
import { axiosInstance, baseUrl } from '@mgk-eld/utils';
import { Box, IconButton, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { toast } from 'react-toastify';

export const handleCopy = (copyText: string) => {
  navigator.clipboard.writeText(copyText);
  toast.info('Copied to clipboard');
};

const TrackingLink: React.FC<{ token: string }> = ({ token }) => {
  const link = useMemo(() => {
    const urlFromInstance = axiosInstance.defaults.baseURL;
    return `${(urlFromInstance ? urlFromInstance : baseUrl.https)
      .replace('api', 'tracking')
      .replaceAll('/api', '')}#/dashboard?access_token=${token}`;
  }, [token]);
  return (
    <Box>
      <Box display={{ xs: 'none', md: 'inline' }} component="span">
        <a href={link} style={{ textDecoration: 'none' }}>
          {link.slice(0, 35)}...
        </a>
      </Box>
      <Tooltip title="Copy" placement="top">
        <IconButton onClick={() => handleCopy(link)} color="info">
          <CopyIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default TrackingLink;

import { Dayjs } from 'dayjs';
import { isEmpty, mapValues } from 'lodash';

import { statuses } from '../../../constants';
import { Draft } from '../../../redux/draft/slice';
import { Row } from './Chart';
import { extendedModes } from './LogEvents';
import { Errors, LogDetail, PropsToCheck } from './types';
import { dayjs, locationRegex } from '@mgk-eld/utils';

export const getSeconds = (date: Date) =>
  (date.getHours() * 60 + date.getMinutes()) * 60 + date.getSeconds();
export const formatZeros = (time: number) => ('0' + time).slice(-2);
export const formatAMPM = (date: Date) => {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  return (
    hours +
    ':' +
    formatZeros(minutes) +
    ':' +
    formatZeros(date.getSeconds()) +
    ' ' +
    ampm
  );
};

export const toHHMMSS = (secs: number) => {
  if (secs === null) {
    return 'N/A';
  }
  const secNum = parseInt(String(secs), 10);
  const hours = Math.floor(secNum / 3600);
  const minutes = Math.floor(secNum / 60) % 60;
  const seconds = secNum % 60;

  return [hours, minutes, seconds].map((v) => (v < 10 ? '0' + v : v)).join(':');
};

export const getDuration = (startDT: Date, endDT: Date) => {
  const differenceInMS = Math.abs(startDT.getTime() - endDT.getTime());
  const date = new Date(0, 0, 0, 0, 0, 0, differenceInMS);
  return `${formatZeros(date.getHours())}h:${formatZeros(
    date.getMinutes()
  )}m:${formatZeros(date.getSeconds())}s`;
};

export const formatDuration = (duration = 0) =>
  duration === 86400
    ? '24:00'
    : new Date(duration * 1000).toISOString().slice(11, 19);

// isSame plugin do not compare with tz
export const isSameDate = (date1: Dayjs, date2: Dayjs) => {
  return (
    date1.format('DD-MM-YYYY hh:mm:ss') === date2.format('DD-MM-YYYY hh:mm:ss')
  );
};

// isToday plugin do not compare with tz
export const isSameDay = (date1: Dayjs, date2: Dayjs) =>
  date1.format('DD/MM/YYYY') === date2.format('DD/MM/YYYY');

export const mapLogDetails2Row = (logDetails: LogDetail[]) => {
  const allMovingStatuses = logDetails.filter((log) => log.type === 'ACTIVITY');
  const movingWithoutIntermediate = allMovingStatuses.filter(
    (log) => log.status !== 'INTERMEDIATE'
  );

  return logDetails.map((logDetail, index) => {
    return {
      ...logDetail,
      id: Date.now() + index,
      originalId: logDetail.id,
      start: dayjs(logDetail.datetime).format('MM/DD/YYYY hh:mm:ss A'),
      end: logDetail.datetime_end
        ? dayjs(logDetail.datetime_end).format('MM/DD/YYYY hh:mm:ss A')
        : '-',
      duration: toHHMMSS(logDetail.duration),
      vehicle: logDetail.vehicle_number,
      odometer: logDetail.odometer_value,
      engHour: logDetail.engine_hours,
      doc: logDetail.shipping_documents,
      isNew: false,
      editing: false,
      errors: checkErrors(
        logDetail,
        allMovingStatuses,
        movingWithoutIntermediate,
        logDetails[index - 1]
          ? logDetailsToPropsToCheck(logDetails[index - 1])
          : undefined
      ),
    };
  });
};

export type LogDetailRows = ReturnType<typeof mapLogDetails2Row>;

export type Editable = {
  disabled: boolean;
  status: boolean;
  start: boolean;
  end: boolean;
  location: boolean;
  vehicle: boolean;
  odometer: boolean;
  engHour: boolean;
  notes: boolean;
  doc: boolean;
};

const initialValue = {
  disabled: false,
  status: true,
  start: true,
  end: true,
  location: true,
  vehicle: false,
  odometer: true,
  engHour: true,
  notes: true,
  doc: true,
};

export const isEditable = (
  rows: Row[],
  date: string,
  id: number,
  endDate?: string
) => {
  const index = rows.findIndex((row) => row.id === id);
  if (index < 0) return;
  const currentDate = dayjs().tz();

  const isPast = dayjs(rows[index].start).isBefore(date, 'date');
  const isFuture = dayjs(rows[index].start).isAfter(endDate || date, 'date');
  const status = rows[index].status;
  const isCurrent =
    (dayjs(rows[index].start).isSame(endDate || date, 'date') ||
      dayjs(rows[index].start).isBefore(currentDate, 'date')) &&
    index + 1 === rows.length;

  let editing: Editable = { ...initialValue };

  // future and past logs are not editable (future and past according to the selected date)
  if (isFuture || isPast) {
    editing.disabled = true;
  }

  // disable editing if the current status is driving
  if (isCurrent && status === statuses[0]) {
    editing = mapValues(editing, () => false);
    editing.start = true;
  }

  if (status === extendedModes[3] || status === extendedModes[4]) {
    editing.engHour = false;
    editing.odometer = false;
    editing.location = false;
  }

  return editing;
};

const isNullOrEmpty = (value?: string | number) => {
  return value === undefined || value === '' || value === null;
};

export const checkIntermediateFreq = (
  logDetail: Partial<LogDetail>,
  errors: Errors,
  prevLog?: PropsToCheck
) => {
  if (logDetail.status === 'INTERMEDIATE' && prevLog) {
    if (prevLog.status === 'DRIVING' || prevLog.status === 'INTERMEDIATE') {
      if (
        dayjs(logDetail.datetime).diff(prevLog.datetime, 'seconds') !== 3600
      ) {
        errors.intermediate_freq = true;
      }
    }
  }
};

const checkForEmpty = (logDetail: Partial<LogDetail>, errors: Errors) => {
  if (!extendedModes.slice(3).includes(logDetail.status || '')) {
    if (
      isNullOrEmpty(logDetail.odometer_value) ||
      isNullOrEmpty(logDetail.engine_hours) ||
      isNullOrEmpty(logDetail.location) ||
      isNullOrEmpty(logDetail.location_latitude) ||
      isNullOrEmpty(logDetail.location_longitude)
    ) {
      errors.empty_check = true;
    }
  }
};

const checkEngineOdometer = (
  logDetail: PropsToCheck,
  errors: Errors,
  prevLog?: PropsToCheck
) => {
  const cert = extendedModes[5];
  const { engine_hours, odometer_value, status } = logDetail;

  // ation status login logout statuses
  if (status === extendedModes[3] || status === extendedModes[4]) {
    return;
  }

  //exclude certification status
  if (prevLog && status !== cert && prevLog.status !== cert) {
    if (engine_hours === null || engine_hours < prevLog.engine_hours) {
      errors.engine_hours = true;
    }
    if (odometer_value === null || odometer_value < prevLog.odometer_value) {
      errors.odometer_check = true;
    }
  }
};

const checkOdometerLocationMatch = (
  logDetail: PropsToCheck,
  movingStatuses: PropsToCheck[],
  errors: Errors
) => {
  const {
    id,
    location,
    status,
    odometer_value,
    location_latitude,
    location_longitude,
  } = logDetail;
  const currentMovingLog = movingStatuses.findIndex((log) => log.id === id);
  const prevMovingLog = movingStatuses[currentMovingLog - 1];

  // ation status login logout statuses
  if (status === extendedModes[3] || status === extendedModes[4]) {
    return;
  }

  if (
    prevMovingLog &&
    (prevMovingLog.status === 'ON_DUTY' ||
      prevMovingLog.status === 'OFF_DUTY' ||
      prevMovingLog.status === 'SLEEPER')
  ) {
    if (prevMovingLog.odometer_value !== odometer_value) {
      errors.odometer_match = true;
    }

    if (!locationRegex.test(logDetail.location)) {
      if (prevMovingLog.location !== location) {
        errors.location_match = true;
      }
    } else if (
      prevMovingLog.location_latitude !== location_latitude ||
      prevMovingLog.location_longitude !== location_longitude
    ) {
      errors.location_match = true;
    }
  }
};

const checkLocation = (
  allMovingStatuses: PropsToCheck[],
  id: number,
  errors: Errors
) => {
  const cMovingLogIndex = allMovingStatuses.findIndex((log) => log.id === id);
  const cMovingLog = allMovingStatuses[cMovingLogIndex];
  const pMovingLog = allMovingStatuses[cMovingLogIndex - 1];

  if (
    dayjs(cMovingLog?.datetime).diff(pMovingLog?.datetime, 'seconds') >= 300
  ) {
    if (
      pMovingLog.location_latitude === cMovingLog.location_latitude &&
      pMovingLog.location_longitude === cMovingLog.location_longitude
    ) {
      errors.location_check = true;
    }
  }
};

const isSameVehicle = (logDetail: PropsToCheck, prevLog?: PropsToCheck) => {
  return prevLog && logDetail.vehicle_id === prevLog.vehicle_id;
};

export const checkErrors = (
  logDetail: PropsToCheck,
  allMovingStatuses: PropsToCheck[],
  movingStatuses: PropsToCheck[],
  prevLog?: PropsToCheck
) => {
  const errors: Errors = {};

  checkIntermediateFreq(logDetail, errors, prevLog);
  checkForEmpty(logDetail, errors);

  // check if the log is in the same vehicle
  if (isSameVehicle(logDetail, prevLog)) {
    checkEngineOdometer(logDetail, errors, prevLog);
    checkOdometerLocationMatch(logDetail, movingStatuses, errors);
    checkLocation(allMovingStatuses, logDetail.id, errors);
  }

  if (!isEmpty(errors)) {
    errors.isValid = false;
  } else {
    errors.isValid = true;
  }

  return errors;
};

export const getErrors = (rows: LogDetailRows, id: number) => {
  const index = rows.findIndex((row) => row.id === id);
  const mappedRows = rows.map((r) => {
    let lat = r.location_latitude;
    let long = r.location_longitude;

    if (locationRegex.test(r.location)) {
      const locations = r.location.replace(/[\s]/g, '').split(',');
      lat = parseFloat(locations[0]);
      long = parseFloat(locations[1]);
    }

    return {
      ...r,
      odometer_value: r.odometer,
      engine_hours: r.engHour,
      location_latitude: lat,
      location_longitude: long,
    };
  });

  const prevLogPropsTocheck = mappedRows[index - 1]
    ? logDetailsToPropsToCheck(mappedRows[index - 1] as any)
    : undefined;
  const curLogPropsTocheck = logDetailsToPropsToCheck(mappedRows[index] as any);
  const moving = mappedRows.filter((r) => r.type === 'ACTIVITY');
  const allMovingStatuses = moving.map((r) =>
    logDetailsToPropsToCheck(r as any)
  );
  const movingWithoutIntermediate = allMovingStatuses.filter(
    (log) => log.status !== 'INTERMEDIATE'
  );

  return checkErrors(
    curLogPropsTocheck,
    allMovingStatuses,
    movingWithoutIntermediate,
    prevLogPropsTocheck
  );
};

export const logDetailsToPropsToCheck = (logDetail: LogDetail) => {
  return {
    id: logDetail.id,
    status: logDetail.status,
    datetime: logDetail.datetime,
    vehicle_id: logDetail.vehicle_id,
    duration: logDetail.duration,
    odometer_value: logDetail.odometer_value,
    engine_hours: logDetail.engine_hours,
    location: logDetail.location,
    location_latitude: logDetail.location_latitude,
    location_longitude: logDetail.location_longitude,
  };
};

type UseDraftProps = {
  logDetails: LogDetailRows;
  drafts: Draft[];
};

export const sortLogs = (rows: LogDetailRows) => {
  return [...rows].sort((a, b) => {
    const d1 = dayjs(a.start).unix();
    const d2 = dayjs(b.start).unix();

    return d1 - d2;
  });
};

export const withDraft = (props: UseDraftProps) => {
  const { logDetails, drafts } = props;

  let mappedLogDetails = logDetails.map((log) => {
    const found = drafts.find((draft) => draft.id === log.id);

    return {
      ...log,
      start: found?.start || found?.start === '' ? found.start : log.start,
      end: found?.end || found?.end === '' ? found.end : log.end,
      status: found?.status ? found.status : log.status,
      location: found?.location ? found.location : log.location,
      odometer: found?.odometer ? found.odometer : log.odometer,
      engHour: found?.engHour ? found.engHour : log.engHour,
      notes: found?.notes || found?.notes === '' ? found.notes : log.notes,
      doc: found?.doc || found?.doc === '' ? found.doc : log.doc ?? '',
      has_been_updated: found?.has_been_updated,
      has_been_deleted: found?.has_been_deleted,
      status_note: found?.status_note || log.status_note,
      editing: found?.editing || log.editing,
      type: log.type,
    };
  });

  drafts.forEach((draft) => {
    if (draft.isNew) {
      mappedLogDetails = [
        ...mappedLogDetails.slice(0, draft.newRowIndex),
        {
          ...logDetails[draft.duplicatedFromId || 0],
          location_latitude: undefined,
          location_longitude: undefined,
          ...draft,
          id: draft.id,
          has_been_updated: draft.has_been_updated,
          has_been_deleted: draft.has_been_deleted,
        },
        ...mappedLogDetails.slice(draft.newRowIndex),
      ];
    }
  });

  return sortLogs(mappedLogDetails);
};

export const dateFormat = 'YYYY-MM-DDTHH:mm:ss';

export const splitLatLng = (location: string, log?: LogDetail) => {
  if (locationRegex.test(location)) {
    const splittedLocation = location.replaceAll(' ', '').split(',');
    return {
      location_latitude: parseFloat(splittedLocation[0]),
      location_longitude: parseFloat(splittedLocation[1]),
    };
  } else return undefined;
};

export const getDate = (date: string) => {
  return (
    dayjs(date).tz(undefined, true).isAfter(dayjs().tz())
      ? dayjs().tz()
      : dayjs(date)
  ).format(dateFormat);
};

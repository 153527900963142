import { PaymentInfo } from '@mgk-eld/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PaymentInfoState {
  paymentInfo: null | PaymentInfo;
}

const initialState: PaymentInfoState = {
  paymentInfo: null,
};

export const paymentInfoSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setPaymentInfo: (state, action: PayloadAction<PaymentInfo | null>) => {
      state.paymentInfo = action.payload;
    },
  },
});

export const { setPaymentInfo } = paymentInfoSlice.actions;

export default paymentInfoSlice.reducer;

import {
  DrivingStatusModeKey,
  green,
  grey,
  orange,
  purple,
  red,
  StatusType,
  yellow,
} from '@mgk-eld/utils';

export const statuses = [
  'DRIVING',
  'ON_DUTY',
  'SLEEPER',
  'OFF_DUTY',
  'PERSONAL_USE',
  'YARD_MOVE',
  'CYCLE',
] as const;

export const driverStatusDefinitions: {
  [k in DrivingStatusModeKey]: StatusType;
} = {
  driving: { color: green, mode: 'DRIVING', short: 'DR' },
  onDuty: { color: yellow, mode: 'ON_DUTY', short: 'OND' },
  sleeper: { color: grey[300], mode: 'SLEEPER', short: 'SL' },
  offDuty: { color: grey[1000], mode: 'OFF_DUTY', short: 'OFFD' },
  personalUse: { color: purple, mode: 'PERSONAL_USE', short: 'PERS' },
  yardMove: { color: orange, mode: 'YARD_MOVE', short: 'YM' },
  cycle: { color: red, mode: 'CYCLE', short: 'CYCLE' },
};

export const logCheckErrors = {
  location_check: 'Location must change from the previous.',
  location_match: 'Location must remain the same as the previous.',
  odometer_check: 'Odometer must be greater than the previous.',
  odometer_match: 'Odometer must remain the same as the previous.',
  engine_hours: 'Engine hours must be greater than the previous.',
  empty_check: 'Empty location/odometer/engine hours fields.',
  intermediate_freq:
    'The Intermediate event must be at exactly +1 hour from the previous event.',
};

export const saveLogeCheckErrors = {
  ...logCheckErrors,
  location_match:
    'Location value match - “Teleport occured. Location must remain the same as the previous.”',
  odometer_match:
    'Odometer value match - “Teleport occured. Odometer must remain the same as the previous.”',
};

export const ALARM_SOUNDS = ['Notification', 'Alarm', 'Alert'];
export const HIGH = 'High';

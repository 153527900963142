import { paymentsApi } from '../../services/payment';
import { AppThunk } from '../store';
import { setPaymentInfo } from './slice';

export const fetchPaymentInfo = (): AppThunk => async (dispatch, getState) => {
  const company_id = getState().companies.selectedId;
  if (!company_id) return;
  try {
    const { data } = await paymentsApi.subscriptionInfo(company_id);
    dispatch(setPaymentInfo(data.error ? null : data));
  } catch (error: any) {
    //
  }
};

import { saveAs } from 'file-saver';
import qs from 'qs';

export const exportExcel = async (
  url: string,
  params?: any,
  filename = 'file.xslx',
  headers = {}
) => {
  const query = params && qs.stringify(params, { arrayFormat: 'repeat' });
  try {
    const response = await fetch(`${url}?${query}`, { headers: headers });

    if (!response.ok) {
      throw new Error();
    }
    const blob = await response.blob();

    // Download the file
    saveAs(blob, filename);
  } catch (error) {
    console.error('Failed to download file', error);
    throw new Error();
  }
};

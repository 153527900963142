import { logsReportValidationSchema } from '@mgk-eld/utils';
import reportsApi, { ReportRequestParams } from '../../../services/reports';
import { Option, ReportItem } from '../types';
import { ReportsItem } from './ReportsItem';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

const LogsReport: React.FC<{ drivers: Option[] }> = ({ drivers }) => {
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingXls, setLoadingXls] = useState(false);

  const onSubmit = useCallback(async (params: ReportRequestParams) => {
    const loadFunc = params.type === 'pdf' ? setLoadingPdf : setLoadingXls;
    loadFunc(true);
    try {
      const { data } = await reportsApi.report('reports/fmcsa', {
        ...params,
        show_title: true,
      });
      window.open(data?.url, '_blank');
    } catch (error: any) {
      toast.error(error.response.data.error);
    }
    loadFunc(false);
  }, []);

  const logsReport = useMemo<ReportItem>(
    () => ({
      onSubmit,
      title: 'Logs report',
      url: 'reports/fmcsa',
      selects: [
        {
          id: 'driver_id',
          title: 'Driver',
          isMulti: false,
          items: drivers,
        },
      ],
      inputs: [],
      validationSchema: logsReportValidationSchema,
      submitButtons: [
        {
          name: 'Generate PDF',
          loading: loadingPdf,
          id: 'pdf',
        },
        {
          name: 'Generate XLS',
          loading: loadingXls,
          id: 'xlsx',
        },
      ],
    }),
    [drivers, loadingPdf, loadingXls, onSubmit]
  );
  return <ReportsItem report={logsReport} />;
};

export default LogsReport;

import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function Page404() {
  return (
    <Wrapper>
      <Typography component="div" variant="h1" align="center" gutterBottom>
        404
      </Typography>
      <Typography component="div" variant="h5" align="center" gutterBottom>
        Page not found{' '}
        <span role="img" aria-label="sad-emoji">
          😔
        </span>
      </Typography>
      <Typography component="div" variant="body1" align="center" gutterBottom>
        The page you are looking for might have been removed
      </Typography>
      <Link to="/">Back</Link>
    </Wrapper>
  );
}

export default Page404;

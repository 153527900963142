import {
  SvgIconProps,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import React from 'react';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} enterTouchDelay={0} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    border: '1px solid #E6E8ED',

    // border: '1px solid black',
  },
  [`& .${tooltipClasses.popper}`]: {
    boxShadow: '10px 5px 5px red;',
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    color: theme.palette.common.white,
  },
}));

type Props = {
  tooltipProps: Omit<TooltipProps, 'children'>;
  iconProps?: SvgIconProps;
  icon?: React.ReactElement;
};

export const InfoIConTooltip: React.FC<Props> = ({
  tooltipProps,
  iconProps,
  icon,
}) => {
  return (
    <LightTooltip {...tooltipProps}>
      {icon ?? <InfoIcon {...iconProps} />}
    </LightTooltip>
  );
};

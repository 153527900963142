import { useAppSelector } from '../hooks/reduxHooks';
import { getUserInfo } from '../redux/auth/selectors';
import { getCompaniesList } from '../redux/companies/selectors';
import { RouteNames } from '../routes';
import { UserRole } from '../types/auth';
import {
  CreateAbility,
  createMongoAbility,
  MongoAbility,
  AbilityBuilder,
} from '@casl/ability';
import { createContextualCan } from '@casl/react';
import { createContext, ReactNode, useEffect, useState } from 'react';

const cantAccessIfEmptyCompanylist = [
  'Users',
  'FleetManagerForm',
  'Drivers',
  'DriversForm',
  'ELD',
  'ELDForm',
  'Vehicles',
  'VehiclesForm',
] as const;

type Actions = 'access' | 'click';
export type Subjects =
  | RouteNames
  | 'CompanySwitch'
  | 'SubscriptionDetails'
  | 'all';

export type AppAbility = MongoAbility<[Actions, Subjects]>;
export const createAppAbility = createMongoAbility as CreateAbility<AppAbility>;

const defineRulesFor = (role: UserRole) => {
  const { can, cannot, build } = new AbilityBuilder(createAppAbility);
  switch (role) {
    case 'Account admin':
      can('click', 'all');
      can('access', 'all');
      break;
    case 'Company owner':
      can('access', 'all');
      can('click', 'all');
      can('access', 'Payment');
      cannot('access', 'SubscriptionDetails');
      cannot('access', 'Companies');
      cannot('access', 'CompanyForm');
      break;
    case 'Fleet manager': // give all in once and subtract what is not needed
      can('access', 'all');
      can('click', 'all');
      cannot('access', 'CompanyProfile');
      cannot('access', 'SubscriptionDetails');
      cannot('click', 'CompanySwitch');
      cannot('access', 'Users');
      cannot('access', 'Companies');
      cannot('access', 'CompanyForm');
      cannot('access', 'FleetManagerForm');
      break;
    case 'withoutCompanies':
      can('access', 'all');
      can('click', 'all');
      cantAccessIfEmptyCompanylist.forEach((p) => cannot('click', p));
  }
  return build();
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const AbilityContext = createContext<AppAbility>(null!);
const Can = createContextualCan(AbilityContext.Consumer);

function AbilityProvider({ children }: { children: ReactNode }) {
  const userInfo = useAppSelector(getUserInfo);
  const companiesList = useAppSelector(getCompaniesList);
  const [ability, setAbility] = useState<AppAbility>(
    defineRulesFor('unauthorized')
  );
  useEffect(() => {
    if (!userInfo) return setAbility(defineRulesFor('unauthorized'));
    if (companiesList.length === 0 && !localStorage.getItem('selectedCompany'))
      return setAbility(defineRulesFor('withoutCompanies'));
    setAbility(defineRulesFor(userInfo.role_name));
  }, [userInfo, companiesList]);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
}

export { AbilityContext, AbilityProvider, Can };

import { PageOptions, StatusMode, axiosInstance } from '@mgk-eld/utils';
import qs from 'qs';

interface Tracking extends PageOptions {
  company_id: number;
  date?: string;
  driver_name?: string;
  vehicle_number?: string;
  statuses?: StatusMode[];
}

const get = (params: Tracking) => {
  return axiosInstance.get(`logs/tracking`, {
    params: params,
    paramsSerializer: {
      serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    },
  });
};

type DriverLocations = {
  driver_id: number;
  company_id: number;
};

const getDriverLocations = (props: DriverLocations) => {
  return axiosInstance.get(`locations`, {
    params: { ...props, type: 'polyline' },
  });
};

const trackingsApi = {
  get,
  getDriverLocations,
};

export default trackingsApi;

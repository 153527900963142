type Timezone = {
  label: string;
  value: string;
  timezone: string;
};

export const timezones: Timezone[] = [
  {
    label: 'Atlantic Standard Time (AST)',
    value: 'AST',
    timezone: 'America/Glace_Bay',
  },
  {
    label: 'Eastern Standard Time (EST)',
    value: 'EST',
    timezone: 'America/New_York',
  },
  {
    label: 'Central Standard Time (CST)',
    value: 'CST',
    timezone: 'America/Chicago',
  },
  {
    label: 'Mountain Standard Time (MST)',
    value: 'MST',
    timezone: 'America/Denver',
  },
  {
    label: 'Pacific Standard Time (PST)',
    value: 'PST',
    timezone: 'America/Los_Angeles',
  },
  {
    label: 'Alaskan Standard Time (AKST)',
    value: 'AKST',
    timezone: 'America/Anchorage',
  },
  {
    label: 'Hawaii-Aleutian Standard Time (HST)',
    value: 'HST',
    timezone: 'US/Aleutian',
  },
  {
    label: 'Samoa standard time (UTC-11)',
    value: 'SST',
    timezone: 'Pacific/Midway',
  },
  {
    label: 'Chamorro Standard Time (UTC+10)',
    value: 'CHST',
    timezone: 'Pacific/Guam',
  },
];

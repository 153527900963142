import { Vehicle } from '@mgk-eld/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface VehicleState {
  vehicles: Vehicle[];
  loading: boolean;
}

const initialState: VehicleState = {
  vehicles: [],
  loading: true,
};

export const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    setVehicles: (state, action: PayloadAction<Vehicle[]>) => {
      state.vehicles = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { setVehicles, setLoading } = vehiclesSlice.actions;

export default vehiclesSlice.reducer;

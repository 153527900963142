import { toast } from 'react-toastify';
import { baseUrl } from './constants';
import { ACCESS_TOKEN, isValidToken, refreshTokenFn } from './jwt';

export interface SocketProps {
  onMessage: (response: any) => void;
  onError?: (error: any) => void;
  path: string;
  params?: Record<string, any>;
}

declare global {
  interface WebSocket {
    sendStop(): void;
    sendMessage(data: object): void;
    sentData: object;
    sentStop: boolean;
  }
}

interface CustomWebSocket extends WebSocket {
  sendStop(): void;
  sendMessage(data: Record<string, any>): void;
  sentData: Record<string, any>;
  sentStop: boolean;
}

class WebSocketService {
  private static instance: WebSocketService;
  private _baseUrl: string;
  private _activeSockets: Set<CustomWebSocket>;

  private constructor() {
    this._baseUrl = baseUrl.socket;
    this._activeSockets = new Set();
    this.setupWebSocketPrototype();
  }

  public static getInstance(): WebSocketService {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  private setupWebSocketPrototype() {
    WebSocket.prototype.sendStop = function (this: CustomWebSocket) {
      this.sentStop = true;
      const auth_token = localStorage.getItem(ACCESS_TOKEN);
      if (this.readyState === WebSocket.OPEN) {
        this.send(JSON.stringify({ command: 'stop', auth_token }));
      }
    };

    WebSocket.prototype.sendMessage = async function (
      this: CustomWebSocket,
      data: Record<string, any>
    ) {
      let auth_token = localStorage.getItem(ACCESS_TOKEN);

      if (!isValidToken(auth_token)) {
        try {
          const newToken = await refreshTokenFn();
          auth_token = newToken;
        } catch (err) {
          console.error('Token refresh failed:', err);
          window.location.replace('/login');
          return;
        }
      }

      this.sentData = data;
      this.send(
        JSON.stringify({
          auth_token,
          data,
        })
      );
    };
  }

  public createWebSocket({
    onMessage,
    onError,
    path,
    params,
  }: SocketProps): CustomWebSocket {
    const queryParams = params
      ? new URLSearchParams(params as any).toString()
      : '';
    const fullPath = queryParams ? `${path}?${queryParams}` : path;
    const socket = new WebSocket(
      `${this._baseUrl}${fullPath}`
    ) as CustomWebSocket;

    socket.sentData = {};
    socket.sentStop = false;

    socket.onopen = () => {
      console.debug(`WebSocket connected: ${path}`);
    };

    socket.onmessage = async (event) => {
      try {
        const response = JSON.parse(event.data);
        if (response.result === 'error') {
          if (response.details === 'Invalid token') {
            return socket.sendMessage(socket.sentData);
          }
          if (response.details !== 'Permission denied') {
            toast.error(`WebSocket error in ${path}: ${response.details}`);
          }
          onMessage(null);
        } else {
          onMessage(response);
        }
      } catch (error) {
        console.error('Failed to parse WebSocket message:', error);
        if (onError) onError(error);
      }
    };

    socket.onclose = (event) => {
      console.info(`WebSocket closed: ${path}`, event.code, event.reason);
      this._activeSockets.delete(socket);
    };

    socket.onerror = (error) => {
      if (socket.readyState !== WebSocket.CLOSED) {
        socket.close();
      }
      if (socket.sentStop) {
        console.log('WebSocket closed before connection established.');
      } else {
        console.error('WebSocket error:', error);
      }
      if (onError) {
        onError(error);
      }
      this._activeSockets.delete(socket);
    };

    this._activeSockets.add(socket);
    return socket;
  }

  public closeSocket(socket: CustomWebSocket) {
    if (socket.readyState === WebSocket.OPEN) {
      socket.sendStop();
      socket.close();
    }
    this._activeSockets.delete(socket);
  }

  public closeAllSockets() {
    this._activeSockets.forEach((socket) => {
      this.closeSocket(socket);
    });
    this._activeSockets.clear();
  }

  public getActiveSocketsCount(): number {
    return this._activeSockets.size;
  }

  public updateBaseUrl(newUrl: string) {
    this._baseUrl = newUrl;
  }

  public getBaseUrl(): string {
    return this._baseUrl;
  }
}

// Create instance
export const wsService = WebSocketService.getInstance();

// Export type
export type { CustomWebSocket };

// Export functions
export const createWebSocket = wsService.createWebSocket.bind(wsService);
export const closeSocket = wsService.closeSocket.bind(wsService);
export const closeAllSockets = wsService.closeAllSockets.bind(wsService);
export const getActiveSocketsCount =
  wsService.getActiveSocketsCount.bind(wsService);
export const updateWsBaseUrl = wsService.updateBaseUrl.bind(wsService);
export const getWsBaseUrl = wsService.getBaseUrl.bind(wsService);

// Helper function for backward compatibility
export const socketInstance = (props: SocketProps): CustomWebSocket => {
  return createWebSocket(props);
};

import {
  GridProps,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import React, { useCallback } from 'react';
import { grey, red, StatusType } from '@mgk-eld/utils';
import { IndicatorIcon } from '@mgk-eld/assets';

type Props = {
  status?: StatusType;
  subText?: string;
  statusNote?: string;
  extended?: { mode: any; color: string };
};

const getStatusModeText = (mode: string) => mode.replaceAll('_', ' ');

export const Status: React.FC<Props & GridProps> = ({
  status,
  subText,
  extended,
  statusNote,
  ...rest
}) => {
  const isCycle = false;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const getPrimaryText = useCallback(
    (mode: string) => {
      const statusText = getStatusModeText(mode);
      return `${statusText} ${subText ? `(${subText})` : ''}`;
    },
    [subText]
  );

  return (
    <Grid
      container
      alignItems="center"
      justifyContent={'center'}
      width="auto"
      sx={{
        py: statusNote ? 0 : 3,
        my: statusNote ? 1 : 0,
      }}
      {...rest}
    >
      <Grid item mt="1px">
        {isCycle ? (
          <IndicatorIcon style={{ marginRight: '8px' }} />
        ) : (
          <Avatar
            sx={{
              width: 10,
              height: 10,
              marginRight: '8px',
              background: extended?.color ?? status?.color,
              '& .MuiSvgIcon-root': {
                display: 'none',
              },
            }}
          />
        )}
      </Grid>
      <ListItemText
        primary={getPrimaryText(
          (isDesktop ? status?.mode : status?.short) ?? extended?.mode
        )}
        secondary={statusNote}
        primaryTypographyProps={{
          typography: 'h5',
          fontWeight: 700,
          textTransform: 'uppercase',
          fontSize: '11px',
          color: isCycle ? red : grey[1000],
          textOverflow: 'ellipsis',
        }}
        secondaryTypographyProps={{
          component: 'span',
          color: grey[1000],
          fontWeight: 400,
          fontSize: '11px',
        }}
      />
    </Grid>
  );
};

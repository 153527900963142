import { dayjs, StatusMode, StatusType } from '@mgk-eld/utils';
import { driverStatusDefinitions } from '../constants';

export const getFormatedDate = (date: string) => {
  const dateObj = dayjs(date).utcOffset(date.slice(-6));

  if (!dateObj.isValid()) return date;
  return `${dateObj.format('DD MMM, YYYY')} at ${dateObj.format('HH:mm')}`;
};

export const getStatus = (value: StatusMode): StatusType => {
  return (
    Object.values(driverStatusDefinitions).find(
      (status: StatusType) => status.mode === value
    ) || driverStatusDefinitions.driving
  );
};

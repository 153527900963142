import { axiosInstance } from '@mgk-eld/utils';

export type ReportType = 'xlsx' | 'pdf';

export type ReportRequestParams = {
  company_id: string | number | null;
  date_from: string;
  date_to: string;
  driver_id?: string | number;
  vehicle_id?: string | number;
  state?: string | number;
  show_title?: boolean;
  type?: ReportType;
  email?: string;
  output_file_comment?: string;
};

const report = (url: string, props: ReportRequestParams) => {
  return axiosInstance.get(`${url}`, {
    params: props,
  });
};

const reportsApi = {
  report,
};

export default reportsApi;

import Box from '@mui/material/Box';
import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import React, { ReactNode } from 'react';

type Props<T> = {
  tabs: Array<string | undefined>;
  tabpanels: Array<ReactNode>;
  setCurrentTab: (tab: T) => void;
  initialIndex?: number;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const Tabs = <T,>(props: Props<T>) => {
  const { tabs, tabpanels, setCurrentTab, initialIndex } = props;

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function MiuTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        sx={{ mt: 2 }}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </Box>
    );
  }

  const [value, setValue] = React.useState(initialIndex ?? 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setCurrentTab(newValue as T);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <MuiTabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabs.map(
            (label, index) =>
              label && (
                <MuiTab key={index} label={label} {...a11yProps(index)} />
              )
          )}
        </MuiTabs>
      </Box>
      {tabpanels.map((content, index) => (
        <MiuTabPanel key={index} value={value} index={index}>
          {content}
        </MiuTabPanel>
      ))}
    </Box>
  );
};

export default Tabs;

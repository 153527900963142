import axios from 'axios';
import { baseUrl } from '../constants';

const getAppConfig = () => {
  const host = window.location.host;
  const url = host.includes('localhost')
    ? baseUrl.https
    : `https://${host.replace('app', 'api').replace('tracking', 'api')}/api/`;
  return axios.get(`${url}schema`);
};

export const configApi = {
  getAppConfig,
};

import { dayjs } from '@mgk-eld/utils';
import { getTimeZone } from '../companies/selectors';
import { RootState } from '../store';
import { createSelector } from '@reduxjs/toolkit';

export const getSimpleDate = (state: RootState) => state.simple.date;

export const getDate = createSelector(
  [getSimpleDate, getTimeZone],
  (date, tz) => {
    const sessionDate = sessionStorage.getItem('date');
    const updatedValue = date
      ? dayjs(date)
      : dayjs(sessionDate ?? undefined).tz(tz, true);
    return updatedValue.tz(tz, true);
  }
);

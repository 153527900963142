import { idlingReportValidationSchema } from '@mgk-eld/utils';
import reportsApi, { ReportRequestParams } from '../../../services/reports';
import { Option, ReportItem } from '../types';
import { ReportsItem } from './ReportsItem';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

const Idling: React.FC<{ vehicles: Option[] }> = ({ vehicles }) => {
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingXls, setLoadingXls] = useState(false);

  const onSubmit = useCallback(async (params: ReportRequestParams) => {
    const loadFunc = params.type === 'pdf' ? setLoadingPdf : setLoadingXls;
    loadFunc(true);
    try {
      const { data } = await reportsApi.report('reports/idling', params);
      window.open(data?.url, '_blank');
    } catch (error: any) {
      toast.error(error.response.data.error);
    }
    loadFunc(false);
  }, []);

  const idling = useMemo<ReportItem>(
    () => ({
      onSubmit,
      title: 'Idling',
      url: 'reports/idling',
      selects: [
        {
          id: 'vehicle_id',
          title: 'Vehicle',
          isMulti: true,
          items: vehicles,
        },
      ],
      inputs: [],
      validationSchema: idlingReportValidationSchema,
      submitButtons: [
        {
          name: 'Generate PDF',
          id: 'pdf',
          loading: loadingPdf,
        },
        {
          name: 'Generate XLS',
          id: 'xlsx',
          loading: loadingXls,
        },
      ],
    }),
    [loadingPdf, loadingXls, onSubmit, vehicles]
  );
  return <ReportsItem report={idling} />;
};

export default Idling;

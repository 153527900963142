import authReducer from './auth/slice';
import companiesReducer from './companies/slice';
import draftsReducer from './draft/slice';
import inputReducer from './simple/slice';
import vehiclesReducer from './vehicles/slice';
import paymentInfoReducer from './subscription/slice';
import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: {
    companies: companiesReducer,
    vehicles: vehiclesReducer,
    drafts: draftsReducer,
    auth: authReducer,
    simple: inputReducer,
    paymentInfo: paymentInfoReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = Promise<any>> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

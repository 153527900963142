import {
  Box,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
} from '@mui/material';
import React, { FC } from 'react';

export const CustomPagination: FC<{
  count?: number;
  page?: number;
  size?: number;
  defaultValue?: number;
  setSize?: (newSize: number) => void;
  handlePageChange?: (e: unknown, nextPage: number) => void;
  withSizeOptinos?: boolean;
}> = ({
  count,
  page,
  handlePageChange,
  setSize,
  size,
  defaultValue,
  withSizeOptinos = true,
}) => {
  return (
    <Box display="flex" sx={{ my: 6 }}>
      <Pagination
        sx={{ mx: 'auto' }}
        count={count}
        page={page}
        onChange={handlePageChange}
        shape="rounded"
        color="primary"
      />
      {withSizeOptinos && (
        <Stack direction="row" justifyContent="center" alignItems="center">
          <InputLabel sx={{ m: '5px' }} id="lines_per_page">
            Lines per page
          </InputLabel>

          <Select
            sx={{ width: 80, height: 40 }}
            id="lines_per_page"
            value={size}
            size="small"
            onChange={(e) => {
              if (setSize) setSize(Number(e.target.value));
            }}
          >
            {defaultValue && (
              <MenuItem value={defaultValue}>{defaultValue}</MenuItem>
            )}
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Stack>
      )}
    </Box>
  );
};

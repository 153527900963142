import { CustomPagination, DataTable } from '@mgk-eld/core';
import { useAppSelector } from '../../hooks/reduxHooks';
import { getSelectedCompanyId } from '../../redux/companies/selectors';
import errorsApi, { ErrorUrls } from '../../services/errors';
import { Warnings as WarningsType, usePagination } from '@mgk-eld/utils';
import DeleteButton from './DeleteButton';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';

const Warnings: React.FC<{ driverName: string; date: string }> = ({
  driverName,
  date,
}) => {
  const [selectedIds, setSelectedIds] = useState<GridRowSelectionModel>([]);
  const [loading, setLoading] = useState(false);
  const [warnings, setWarnings] = useState<WarningsType[]>([]);
  const { page, size, count, setSize, setTotal, handlePageChange } =
    usePagination();
  const selectedCompany = useAppSelector(getSelectedCompanyId);

  const loadData = useCallback(async () => {
    if (!selectedCompany) return;
    setLoading(true);
    const { data } = await errorsApi.load(
      ErrorUrls.Warnings,
      { page, size },
      { company_id: selectedCompany, date, driver_name: driverName }
    );
    setLoading(false);
    setWarnings(data.items);
    setTotal(data.total);
  }, [date, driverName, page, selectedCompany, setTotal, size]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleDelete = useCallback(
    async (selectedId?: number) => {
      const idListToDelete = selectedId ? [selectedId] : selectedIds;
      if (!selectedCompany) return;
      await errorsApi.deleteErrors(ErrorUrls.Warnings, idListToDelete);
      loadData();
    },
    [loadData, selectedCompany, selectedIds]
  );

  const cols: GridColDef[] = useMemo(
    () => [
      {
        field: 'driver',
        headerName: 'DRIVER',
        flex: 0.7,
        headerAlign: 'left',
        sortable: false,
      },
      {
        field: 'vehicle',
        headerName: 'VEHICLE',
        flex: 0.5,
        headerAlign: 'left',
        sortable: false,
      },
      {
        field: 'warnings',
        headerName: 'WARNINGS',
        flex: 0.5,
        headerAlign: 'left',
        sortable: false,
      },
      {
        field: 'startAddress',
        headerName: 'START ADDRESS',
        flex: 1,
        headerAlign: 'left',
        sortable: false,
      },
      {
        field: 'delete',
        align: 'center',
        headerAlign: 'center',
        flex: 0.2,
        sortable: false,
        renderHeader: () => (
          <DeleteButton
            isSelected={selectedIds.length > 0}
            text="all selected Warnings"
            onDelete={handleDelete}
          />
        ),
        renderCell: (props) => (
          <DeleteButton
            id={props.row.id}
            text="this Warning"
            onDelete={handleDelete}
            isSelected={selectedIds.includes(props.row.id)}
          />
        ),
      },
    ],
    [handleDelete, selectedIds]
  );
  const rows = useMemo(
    () =>
      warnings.map((warning) => ({
        id: warning.id,
        driver: `${warning.driver_name} ${warning.driver_surname}`,
        vehicle: warning.vehicle_number,
        warnings: warning.warning_text,
        startAddress: warning.start_address,
      })),
    [warnings]
  );
  return (
    <>
      <DataTable
        loading={loading}
        rows={rows}
        autoHeight
        columns={cols}
        onRowSelectionModelChange={(ids) => setSelectedIds(ids)}
        rowSelectionModel={selectedIds}
        disableRowSelectionOnClick
      />
      {count > 0 && (
        <CustomPagination
          count={count}
          handlePageChange={handlePageChange}
          size={size}
          setSize={setSize}
          page={page}
        />
      )}
    </>
  );
};

export default Warnings;

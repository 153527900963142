import { PaletteOptions } from '@mui/material';
import { ThemeConfig } from '../types';
import breakpoints from './breakpoints';
import components from './components';
import shadows from './shadows';
import typography from './typography';
import variants, { customBlue } from './variants';
import createMuiTheme from '@mui/material/styles/createTheme';
import { grey } from './colors';

export const createTheme = (data?: ThemeConfig) => {
  const defaultPalette = variants.palette;
  const defaultHeader = variants.header;
  const defaultSidebar = variants.sidebar;

  const palette: PaletteOptions = data
    ? {
        ...defaultPalette,
        primary: { ...defaultPalette.primary, main: data.primary },
        secondary: { ...defaultPalette.secondary, main: data.secondary },
        info: data.info
          ? { ...defaultPalette.info, main: data.info }
          : defaultPalette.info,
        warning: data.warning
          ? { ...defaultPalette.warning, main: data.warning }
          : defaultPalette.warning,
      }
    : defaultPalette;

  const header = data
    ? { ...defaultHeader, color: data.primary }
    : defaultHeader;

  const sidebar = data
    ? {
        color: grey[300],
        background: data.primary,
        activeItem: {
          color: data.primary,
          background: 'white',
          borderRadius: '8px',
        },
        header: {
          color: grey[300],
          background: data.primary,
          brand: {
            color: customBlue[500],
          },
        },
        badge: {
          color: '#FFF',
          background: customBlue[500],
        },
      }
    : defaultSidebar;
  return createMuiTheme(
    {
      spacing: 4,
      breakpoints: breakpoints,
      // @ts-expect-error "not strictly compatible"
      components: components,
      typography: typography,
      shadows: shadows,
      palette,
    },
    {
      name: variants.name,
      header,
      sidebar,
    }
  );
};

export * from './colors';

import styled from '@emotion/styled';
import { englishString, grey } from '@mgk-eld/utils';
import IconButton from '@mui/material/IconButton';
import MiuInput, { InputProps } from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import { useFormik } from 'formik';
import React from 'react';
import { ISchema, Reference, object } from 'yup';

export const CustomPaper = styled(Paper)`
  border: 1px solid ${grey[100]};
  border-radius: 8px;
  background: ${grey[50]};
  height: 40px;
`;

type Props = {
  placeholder: string;
  initialValue?: string;
  handleSubmit?: (val: string) => void;
  handleChange?: (val: string) => void;
  width?: string | number;
  height?: string | number;
  validations?: ISchema<unknown> | Reference<unknown>;
  name?: string;
  Icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
};

export const Input: React.FC<Props & InputProps> = ({
  Icon,
  placeholder,
  initialValue,
  handleSubmit,
  handleChange,
  validations,
  name,
  width = '100%',
  height = '40px',
  ...rest
}) => {
  const {
    handleSubmit: handleLocalSubmit,
    handleChange: handleLocalChange,
    values,
    touched,
    errors,
  } = useFormik({
    initialValues: {
      val: initialValue,
    },
    validationSchema: object({
      val: validations ?? englishString,
    }),
    onSubmit: ({ val }) => {
      if (!handleSubmit) return;
      handleSubmit(val ?? '');
    },
  });

  return (
    <form
      style={{ width: width }}
      onSubmit={handleLocalSubmit}
      onBlur={handleLocalSubmit}
    >
      <CustomPaper
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width: width,
          height: height,
          border: errors.val ? 'solid 1px red !important' : 'inherit',
        }}
      >
        <MiuInput
          {...rest}
          id="val"
          error={touched.val && Boolean(errors.val)}
          name={name}
          sx={{ ml: 1, flex: 1 }}
          type="text"
          placeholder={placeholder}
          disableUnderline
          value={values.val ?? initialValue}
          onChange={(e) => {
            if (handleChange) handleChange(e.target.value);
            handleLocalChange(e);
          }}
          inputProps={{ 'aria-label': placeholder }}
        />
        {Icon && (
          <IconButton type="submit" sx={{ p: '10px' }} aria-label={placeholder}>
            <Icon />
          </IconButton>
        )}
      </CustomPaper>
    </form>
  );
};

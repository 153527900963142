import { MenuOption, white } from '@mgk-eld/utils';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material';
import React, { useCallback } from 'react';

export const MultiSelect: React.FC<
  {
    items: MenuOption[];
    showErrorMessage: boolean;
    errorMessage: string;
    setValue: (val: string) => void;
    value: string;
  } & SelectProps
> = ({
  items,
  showErrorMessage,
  errorMessage,
  setValue,
  id,
  error,
  title,
  value,
  disabled,
  ...rest
}) => {
  const handleChange = useCallback(
    (e: SelectChangeEvent<string[]>) => {
      const val = e.target.value as string[];
      let newVal;
      if (val.includes('all') && !value.includes('all')) {
        newVal = ['all'];
      } else newVal = val.filter((v) => v !== 'all');
      setValue(newVal.join(','));
    },
    [setValue, value]
  );

  const renderSelectValue = useCallback(
    (state: string[]) => {
      const val = items.reduce<string>(
        (acc, option) =>
          state.includes(option.value) ? `${acc}${option.title}, ` : acc,
        ''
      );

      return val.endsWith(', ') ? val.slice(0, -2) : val;
    },
    [items]
  );

  return (
    <FormControl id={id} variant="filled" fullWidth error={error}>
      <InputLabel id={id}>{title}</InputLabel>
      <MuiSelect
        multiple
        id="state"
        label="Status"
        value={value.split(',').filter((v) => v)}
        renderValue={renderSelectValue}
        onChange={handleChange}
      >
        {items.map((item) => (
          <MenuItem
            sx={{
              backgroundColor: `${
                value.includes(item.value) ? '#E5EAED' : white
              } !important`,
            }}
            key={item.value}
            value={item.value}
          >
            {item.title}
          </MenuItem>
        ))}
      </MuiSelect>
      {showErrorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

import {
  actionOrange,
  actionInfo,
  actionDisabled,
  grey,
  red,
  white,
  actionError,
  actionSuccess,
} from './colors';

export const customBlue = {
  50: '#e9f0fb',
  100: '#c8daf4',
  200: '#a3c1ed',
  300: '#7ea8e5',
  400: '#6395e0',
  500: '#4782da',
  600: '#407ad6',
  700: '#376fd0',
  800: '#2f65cb',
  900: '#2052c2 ',
};

const defaultVariant = {
  name: 'Default',
  palette: {
    primary: {
      main: grey[1000],
      contrastText: '#FFF',
    },
    secondary: {
      main: customBlue[500],
      contrastText: '#FFF',
    },
    error: {
      main: actionError,
      contrastText: '#FFF',
    },
    warning: {
      main: actionOrange,
    },
    background: {
      default: '#FFF',
      paper: '#FFF',
    },
    info: {
      main: actionInfo,
      contrastText: '#fff',
    },
    disabled: {
      main: actionDisabled,
      contrastText: grey[1000],
    },
    success: {
      main: actionSuccess,
      contrastText: '#fff',
    },
  },
  header: {
    color: grey[1000],
    background: '#FFF',
    indicator: {
      background: red,
    },
  },
  sidebar: {
    color: grey[300],
    background: grey[1000],
    activeItem: {
      color: grey[1000],
      background: white,
      borderRadius: '8px',
    },
    header: {
      color: grey[300],
      background: grey[1000],
      brand: {
        color: customBlue[500],
      },
    },
    badge: {
      color: '#FFF',
      background: customBlue[500],
    },
  },
};

export default defaultVariant;

export type VariantType = {
  name: string;
  palette: {
    primary: MainContrastTextType;
    secondary: MainContrastTextType;
  };
  header: ColorBgType & {
    indicator: {
      background: string;
    };
  };
  footer: ColorBgType;
  sidebar: ColorBgType & {
    header: ColorBgType & {
      brand: {
        color: string;
      };
    };
    footer: ColorBgType & {
      online: {
        background: string;
      };
    };
    badge: ColorBgType;
  };
};

type MainContrastTextType = {
  main: string;
  contrastText: string;
};
type ColorBgType = {
  color: string;
  background: string;
};

import { Input, Loader, SingleSelect } from '@mgk-eld/core';
import authApi from '../../services/auth';
import TrackingLink from './TrackingLink';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

type DialogProps = {
  open: number | false;
  setOpen: Function;
  onSubmit: Function;
};

const expirationDates = [
  { title: '12 hours', value: '720' },
  { title: '1 day', value: '1440' },
  { title: '2 days', value: '2880' },
  { title: '3 days', value: '4320' },
  { title: '4 days', value: '5760' },
  { title: '5 days', value: '7200' },
  { title: '6 days', value: '8640' },
  { title: '7 days', value: '10080' },
];

enum DestinationType {
  Coordinates = 'Coordinates',
  Address = 'Address',
}

const isCoordinatesValid = (lat: any, lng: any) => {
  if (isNaN(Number(lat)) || isNaN(Number(lng))) return false;
  if (lat > 90 || lat < -90) return false;
  if (lng > 180 || lng < -180) return false;
  return true;
};

const GenerateLinkDialog: React.FC<DialogProps> = ({
  open,
  setOpen,
  onSubmit,
}) => {
  const [period, setPeriod] = useState(expirationDates[0].value);
  const [destination, setDestination] = useState('');
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);
  const [destinationType, setDestinationType] = useState<DestinationType>(
    DestinationType.Address
  );

  const getDestination = useCallback(
    (val: string) => {
      if (destinationType === DestinationType.Address)
        return { destination: val };
      const [lat, lng] = val.split(',', 2);
      if (!isCoordinatesValid(lat, lng)) {
        toast.error('Invalid coordinates provided');
        throw new Error();
      }
      return {
        destination_latitude: lat,
        destination_longitude: lng,
      };
    },
    [destinationType]
  );
  const handleGenerate = async () => {
    if (!open) return;
    setLoading(true);
    try {
      const dest = getDestination(destination);
      const body = {
        driver_id: open,
        expire_minutes: Number(period),
        ...dest,
      };
      const {
        data: { access_token },
      } = await authApi.getDriverToken(body);
      setToken(access_token);
      toast.success('Token created');
    } catch (error) {
      console.log(error);
    }
    onSubmit();
    setLoading(false);
  };
  return (
    <Dialog onClose={() => setOpen(false)} open={!!open}>
      <Box justifyContent="center" alignItems="center" sx={{ p: 6 }}>
        <Stack
          width={300}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>Link Expiration:</Typography>
          <div style={{ width: 130 }}>
            <SingleSelect
              title="Select period"
              value={period}
              items={expirationDates}
              setValue={(val) => setPeriod(val)}
            />
          </div>
        </Stack>
        <Stack direction="row" mt={5} justifyContent="space-between">
          <Typography mb={2}>Destination:</Typography>
          <ToggleButtonGroup
            sx={{ height: 20 }}
            color="info"
            value={destinationType}
            exclusive
            size="small"
            onChange={(e, a) => setDestinationType(a)}
          >
            <ToggleButton value={DestinationType.Address}>
              {DestinationType.Address}
            </ToggleButton>
            <ToggleButton value={DestinationType.Coordinates}>
              {DestinationType.Coordinates}
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <Input
          handleChange={(v) => setDestination(v)}
          placeholder={destinationType}
        />
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          mt={4}
        >
          {token ? (
            <>
              <TrackingLink token={token} />
              <Tooltip title="Close" placement="top">
                <IconButton onClick={() => setOpen(false)} color="error">
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : loading ? (
            <Loader />
          ) : (
            <Button
              onClick={handleGenerate}
              variant="contained"
              color="secondary"
              sx={{ width: '100%' }}
            >
              Create Link
            </Button>
          )}
        </Stack>
      </Box>
    </Dialog>
  );
};

export default GenerateLinkDialog;

import { ExtendedStatusMode } from "../../pages/Logs/components/types";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type Draft = {
  id: number;
  start?: string;
  end?: string;
  isNew?: boolean;
  editing?: boolean;
  status?: ExtendedStatusMode;
  location?: string;
  location_latitude?: number;
  location_longitude?: number;
  odometer?: number;
  engHour?: number;
  notes?: string;
  doc?: string;
  duration?: string;
  newRowIndex?: number;
  duplicatedFromId?: number;
  has_been_updated?: boolean;
  has_been_added?: boolean;
  has_been_deleted?: boolean;
  type?: "ACTIVITY" | "EVENT";
  status_note?: string;
};

export interface DraftState {
  drafts: Draft[];
}

const initialState: DraftState = {
  drafts: [],
};

export const draftSlice = createSlice({
  name: "draft",
  initialState,
  reducers: {
    setDraft: (state, action: PayloadAction<Draft>) => {
      const index = state.drafts.findIndex(
        (draft) => draft.id === action.payload.id
      );

      if (index >= 0) {
        state.drafts[index] = { ...state.drafts[index], ...action.payload };
      } else {
        state.drafts.push(action.payload);
      }
    },
    removeFromDraft: (state, action: PayloadAction<number>) => {
      state.drafts = state.drafts.filter(
        (draft) => draft.id !== action.payload
      );
    },
    unsetDrafts: (state) => {
      state.drafts = [];
    },
  },
});

export const { setDraft, removeFromDraft, unsetDrafts } = draftSlice.actions;

export default draftSlice.reducer;

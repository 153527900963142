import { getSeconds } from "../utils";
import { Box } from "@mui/system";

export type ShiftType = { date?: Date; content?: string };

export const Shift: React.FC<ShiftType> = ({
  date = new Date(),
  content = "14",
}) => {
  return (
    <Box
      className="Shift"
      sx={{
        left: `calc(0% + (100%/86400)*${getSeconds(date)})`,
        ":before": {
          content: `"${content}"`,
        },
      }}
    ></Box>
  );
};

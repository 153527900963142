import { useState } from 'react';
import { CustomDialog } from '@mgk-eld/core';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const Logout = () => {
  const [open, setOpen] = useState(true);
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    await signOut();
    setOpen(false);
    navigate('/login');
  };

  const handleClose = () => {
    navigate(-1);
  };
  return (
    <CustomDialog
      questionText="Do you want to Log out?"
      actionText="Log out"
      actionColor="secondary"
      open={open}
      setOpen={handleClose}
      handleSubmit={handleSignOut}
    />
  );
};

export default Logout;

import { asyncComponent } from '@mgk-eld/core';
import AuthGuard from './components/guards/AuthGuard';
import GuestGuard from './components/guards/GuestGuard';
import AuthLayout from './layout/Auth';
import Layout from './layout/Layout';
import { Reports } from './pages/Reports';
import { Trackings } from './pages/Trackings';
import Login from './pages/auth/Login';
import Logout from './pages/auth/Logout';
import Page404 from './pages/auth/Page404';
import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

const WakeUp = asyncComponent(() => import('./pages/WakeUp'));
// const Reports = asyncComponent(() => import("./pages/Reports"));
const ELD = asyncComponent(() => import('./pages/Eld'));
const ELDForm = asyncComponent(() => import('./pages/Eld/ELDForm'));
const Drivers = asyncComponent(() => import('./pages/Users/Drivers'));
const Users = asyncComponent(() => import('./pages/Users/Users'));
const DriverForm = asyncComponent(() => import('./pages/Users/DriverForm'));
const FleetManagerForm = asyncComponent(
  () => import('./pages/Users/FleetManagerForm')
);
const Dashboard = asyncComponent(() => import('./pages/Dashboard/index'));
const Companies = asyncComponent(() => import('./pages/Companies'));
const CompanyForm = asyncComponent(() => import('./pages/Companies/Form'));
const Vehicles = asyncComponent(() => import('./pages/Vehicle'));
const VehicleForm = asyncComponent(() => import('./pages/Vehicle/VehicleForm'));
const Events = asyncComponent(() => import('./pages/Events'));
const UserProfileForm = asyncComponent(
  () => import('./pages/Users/UserProfile')
);
const Logs = asyncComponent(() => import('./pages/Logs'));
const LogDetails = asyncComponent(
  () => import('./pages/Logs/components/LogDetails')
);
const Errors = asyncComponent(() => import('./pages/Errors'));
const Payment = asyncComponent(() => import('./pages/Payment/Payment'));

const Dots = asyncComponent(() => import('./pages/Dots'));
const DotForm = asyncComponent(() => import('./pages/Dots/form'));

// const Monitoring = asyncComponent(() => import('./pages/Monitoring'));

// const AccountUsers = asyncComponent(() => import('./pages/AccountUsers'));

export type RouteNames =
  | 'Dashboard'
  | 'Logs'
  | 'LogDetails'
  | 'Trackings'
  | 'Errors'
  | 'Reports'
  | 'WakeUp'
  | 'DOT Inspections'
  | 'DOT Form'
  | 'KnowledgeBase'
  | 'FeedbackReport'
  | 'CompanyProfile'
  | 'UserProfile'
  | 'Users'
  | 'FleetManagerForm'
  | 'Companies'
  | 'CompanyForm'
  | 'Drivers'
  | 'DriversForm'
  | 'ELD'
  | 'ELDForm'
  | 'Vehicles'
  | 'VehiclesForm'
  | 'Logout'
  | 'Events'
  | 'Payment';
// | 'Monitoring'
// | 'AccountUsers';

type routeType = {
  name: RouteNames;
  path: string;
  element: React.ReactElement;
  children?: any;
};

const privateRoutes: routeType[] = [
  { path: '/', element: <Navigate to="/dashboard" />, name: 'Dashboard' },
  {
    path: '/dashboard',
    element: <Dashboard />,
    name: 'Dashboard',
  },
  {
    path: '/logs',
    element: <Logs />,
    name: 'Logs',
  },
  {
    path: '/logs/details',
    element: <LogDetails />,
    name: 'LogDetails',
  },
  {
    path: '/trackings',
    element: <Trackings />,
    name: 'Trackings',
  },
  {
    path: '/errors',
    element: <Errors />,
    name: 'Errors',
  },
  // {
  //   path: '/monitoring',
  //   element: <Monitoring />,
  //   name: 'Monitoring',
  // },
  {
    path: '/reports',
    element: <Reports />,
    name: 'Reports',
  },
  {
    path: '/wake-up',
    element: <WakeUp />,
    name: 'WakeUp',
  },
  {
    path: '/dots',
    element: <Dots />,
    name: 'DOT Inspections',
  },
  {
    path: '/dots/form/:id',
    element: <DotForm />,
    name: 'DOT Form',
  },
  {
    path: '/users',
    element: <Users />,
    name: 'Users',
  },
  {
    path: '/users/fleet-manager-form/:id',
    element: <FleetManagerForm />,
    name: 'FleetManagerForm',
  },
  {
    path: '/drivers',
    element: <Drivers />,
    name: 'Drivers',
  },
  {
    path: '/drivers/form/:id',
    element: <DriverForm />,
    name: 'DriversForm',
  },
  {
    path: '/eld',
    element: <ELD />,
    name: 'ELD',
  },
  { path: 'eld/form/:id', element: <ELDForm />, name: 'ELDForm' },
  {
    path: '/events',
    element: <Events />,
    name: 'Events',
  },
  {
    path: '/vehicles',
    element: <Vehicles />,
    name: 'Vehicles',
  },
  {
    path: 'vehicles/form/:id',
    element: <VehicleForm />,
    name: 'VehiclesForm',
  },
  {
    path: '/companies',
    element: <Companies />,
    name: 'Companies',
  },
  {
    path: '/companies/form/:id',
    element: <CompanyForm />,
    name: 'CompanyForm',
  },
  {
    path: '/kb',
    element: <div>123</div>,

    name: 'KnowledgeBase',
  },
  {
    path: '/feedback',
    element: <div>123</div>,
    name: 'FeedbackReport',
  },
  {
    path: '/user-profile',
    element: <UserProfileForm />,
    name: 'UserProfile',
  },
  {
    path: '/company-profile',
    element: <CompanyForm />,
    name: 'CompanyProfile',
  },
  {
    path: '/logout',
    element: <Logout />,
    name: 'Logout',
  },
  {
    path: '/payment',
    element: <Payment />,
    name: 'Payment',
  },
  // {
  //   path: '/accountusers',
  //   element: <AccountUsers />,
  //   name: 'AccountUsers',
  // },
];

const routes: RouteObject[] = [
  {
    path: 'login',
    element: (
      <GuestGuard>
        <AuthLayout children={<Login />} />
      </GuestGuard>
    ),
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <Layout children={undefined} />
      </AuthGuard>
    ),
    children: privateRoutes,
  },
  {
    path: '*',
    element: <Page404 />,
  },
];

export default routes;

import { useState, useEffect } from 'react';

export const usePagination = (
  defaultSize?: number
): {
  page: number;
  size: number;
  count: number;
  setSize: React.Dispatch<React.SetStateAction<number>>;
  setTotal: React.Dispatch<React.SetStateAction<number | null>>;
  handlePageChange: (e: unknown, nextPage: number) => void;
} => {
  const [total, setTotal] = useState<number | null>(null);
  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(defaultSize ?? 20);
  const handlePaginationChange = (e: unknown, nextPage: number) => {
    setPage(nextPage);
  };

  useEffect(() => {
    setPage(1);
  }, [size]);

  return {
    page,
    size,
    count: total && size ? Math.ceil(total / size) : 0, // count
    setSize,
    setTotal,
    handlePageChange: handlePaginationChange,
  };
};

import { asyncComponent, GlobalStyle } from '@mgk-eld/core';
import Sidebar from '../components/sidebar/Sidebar';
import getNavItems from '../components/sidebar/dashboardItems';
import { Subjects } from '../contexts/AbilityContext';
import useAbility from '../hooks/useAbility';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MuiPaper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import { spacing } from '@mui/system';
import React, { ReactNode, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { getAppConfigFromStorage } from '@mgk-eld/utils';

const Navbar = asyncComponent(() => import('../components/navbar/Navbar'));

const drawerWidth = 226;
const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: hidden;
`;
const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Layout: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const ability = useAbility();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const appConfig = useMemo(getAppConfigFromStorage, []);

  const dashboardItems = useMemo(
    () => getNavItems(isDesktop, appConfig),
    [isDesktop, appConfig]
  );
  const items = useMemo(
    () =>
      dashboardItems.map((group) => ({
        ...group,
        pages: group.pages
          .filter((page) => ability.can('access', page.title as Subjects))
          .map((page) =>
            ability.can('click', page.title)
              ? page
              : { ...page, disabled: true }
          ),
      })),
    [ability, dashboardItems]
  );

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={items}
          />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={items}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent pt={isLgUp ? 2 : 2} px={isLgUp ? 6 : 4}>
          {children}
          <Outlet />
        </MainContent>
      </AppContent>
    </Root>
  );
};

export default Layout;
